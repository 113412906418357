import { graphql, PageProps } from "gatsby"
import React from "react"

import { CategoryPageContext } from "../interfaces/pageContext"
import CollectionPage from "./CollectionPage"

export type CategorySalePageProps = PageProps<any, CategoryPageContext>

const CategorySalePage: React.FC<CategorySalePageProps> = (props) => {
  const { pageContext } = props
  const { category } = pageContext
  return (
    <CollectionPage
      name={`${category} Sale`}
      description={`${category} Sale bei buybags.de. Entdecke jetzt eine riesige Auswahl an reduzierten Designer Taschen. Hier Handtaschen, Umhängetaschen und Shopper für Damen günstig online kaufen.`}
      primaryKeyword={`${category} Sale`}
      secondaryKeyword={`Spare bis zu 50%`}
      {...props}
    />
  )
}

export default CategorySalePage

export const query = graphql`
  query CategorySalePageQuery($category: String!, $skip: Int!, $limit: Int!) {
    allProductsCsv(
      filter: { fields: { category: { eq: $category }, sale: { eq: true } } }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          fields {
            brand
            colors
            deepLink
            deliveryCost
            deliveryTime
            description
            gtin
            images {
              alt
              src
              title
            }
            material
            name
            price
            priceBefore
            sale
            slug
          }
        }
      }
      totalCount
    }
  }
`
